<template>
    <div id="tendances" class="page-wrapper page-tendances">
        <!-- Details -->
        <section class="article-details site-max-width off-mobile" v-if="contentIsLoaded">
            <div class="article-intro site-max-width-reading">
                <h2 class="small-title suptitle">{{ $t('Tendances') }}</h2>
                <h1 class="big-title title">{{ content.title }}</h1>
                <p class="regular-text intro-text"><span v-html="content.description"></span></p>
            </div>
            <img :src="content.imageSimple2.url" :alt="content.imageSimple2.titre">
            <article class="article-content wysiwyg site-max-width-reading">
                <div v-html="content.contentWysiwyg"></div>
            </article>
        </section>

        <!-- Articles -->
        <section class="articles-listing site-max-width" v-if="pageIsLoaded">
            <h3 class="medium-title listing-title">{{ $t('À lire également') }}</h3>
            <article class="article-item" v-for="item in autresArticles" :key="item.id">
                <router-link :to="item.jsonUrl" title="">
                    <img :src="item.imageSimple1.url" :alt="item.imageSimple1.titre">
                    <h3 class="small-title article-title" >{{ item.title }}</h3>
                    <p class="regular-text article-text">{{ item.description }}</p>
                    <span class="article-date">{{ moment(item.postDate.date).format('LL') }}</span>
                    <svg height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="60" height="60" fill="#59BBEB"/>
                        <path d="M30.7006 23.6724L35.835 28.8057L19 28.8057L19 31.1944L35.835 31.1944L30.7006 36.3277L32.3729 38L40.3717 30L32.3728 22L30.7006 23.6724Z" fill="white"/>
                    </svg>
                </router-link>
            </article>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
    name: 'TendancesEntry',

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data

                }
            },
            immediate: true,
        },
    },

    mounted () {

    },

    computed: {
        autresArticles() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.articlesEntryShort.data.filter(i => i.jsonUrl !== this.content.jsonUrl ).slice(0, 2)
        },
        moment() {
            moment.locale(this.$route.meta.lang)
            return moment
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
